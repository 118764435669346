import React, { useState, useEffect } from 'react';
import './Alert.css';
import { fetchProducts } from './productService';

const estadosMexicanos = [
  "Aguascalientes", "Baja California", "Baja California Sur", "Campeche", "Chiapas",
  "Chihuahua", "Ciudad de México", "Coahuila", "Colima", "Durango",
  "Guanajuato", "Guerrero", "Hidalgo", "Jalisco", "México",
  "Michoacán", "Morelos", "Nayarit", "Nuevo León", "Oaxaca",
  "Puebla", "Querétaro", "Quintana Roo", "San Luis Potosí", "Sinaloa",
  "Sonora", "Tabasco", "Tamaulipas", "Tlaxcala", "Veracruz",
  "Yucatán", "Zacatecas"
];

const Alert = () => {
  const [visible, setVisible] = useState(false);
  const [product, setProduct] = useState(null);
  const [ubicacion, setUbicacion] = useState('');

  useEffect(() => {
    const fetchAndDisplayProduct = async () => {
      try {
        const products = await fetchProducts();
        if (products.length > 0) {
          // Espera 30 segundos antes de mostrar la primera alerta
          setTimeout(() => {
            const showProduct = () => {
              const randomProduct = products[Math.floor(Math.random() * products.length)];
              const randomUbicacion = estadosMexicanos[Math.floor(Math.random() * estadosMexicanos.length)];
              setProduct(randomProduct);
              setUbicacion(randomUbicacion);
              setVisible(true);

              setTimeout(() => {
                setVisible(false);
                // Siguiente alerta después de un total de 25 segundos
                setTimeout(showProduct, 20000);
              }, 5000); // La alerta se muestra por 5 segundos
            };

            showProduct();
          }, 30000);  // Comienza después de 30 segundos
        }
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchAndDisplayProduct();
  }, []);

  if (!visible || !product) return null;

  return (
    <div className={`alert ${visible ? 'visible' : ''}`}>
      ¡Alguien acaba de comprar {product.titulo} en {ubicacion}!
      <img src={product.imagen1} alt={product.titulo} style={{ width: '50px', marginRight: '10px' }} />
      <button onClick={() => setVisible(false)} className="close-btn">X</button>
    </div>
  );
};

export default Alert;
