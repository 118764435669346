import React, { useState, useEffect } from 'react';
import './Timer.css'; // Importa el archivo CSS

const calculateTimeLeft = () => {
  const now = new Date();
  const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 22, 0, 0);
  const difference = endOfDay - now;
  let timeLeft = {
    hours: 0,
    minutes: 0,
    seconds: 0
  };

  if (difference > 0) {
    timeLeft = {
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
};

const findChangedSegment = (oldTime, newTime) => {
  for (let key in oldTime) {
    if (oldTime[key] !== newTime[key]) {
      return key;
    }
  }
  return '';
};

const shouldShowBanner = () => {
  const now = new Date();
  const currentHour = now.getHours();
  return currentHour < 22 && currentHour >= 7;
};

const CountdownTimer = () => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [changedSegment, setChangedSegment] = useState('');
  const [isBannerVisible, setIsBannerVisible] = useState(shouldShowBanner());

  useEffect(() => {
    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();
      const changedSegment = findChangedSegment(timeLeft, newTimeLeft);
      setTimeLeft(newTimeLeft);
      setChangedSegment(changedSegment);
      setIsBannerVisible(shouldShowBanner());
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  if (!isBannerVisible) {
    return null;
  }

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    let intervalLabel = '';
    if (interval === 'hours') intervalLabel = 'horas';
    if (interval === 'minutes') intervalLabel = 'minutos';
    if (interval === 'seconds') intervalLabel = 'segundos';

    timerComponents.push(
      <span key={interval} className={`time-segment ${interval === changedSegment ? 'animate' : ''}`}>
        <span>{timeLeft[interval]}</span>
        <label>{intervalLabel}</label>
      </span>
    );
  });

  return (
    <div className="countdown-timer">
      <div className="timer-text">🚚El envio gratis termina en🚚</div>
      <div className="timer-content">
        <span className="emoji">👉</span> {/* Emoji de señalamiento a la izquierda */}
        {timerComponents.length ? (
          timerComponents
        ) : (
          <span>¡Oferta Terminada!</span>
        )}
        <span className="emoji">👈</span> {/* Emoji de señalamiento a la derecha */}
      </div>
    </div>
  );
};

export default CountdownTimer;
