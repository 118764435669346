// src/Components/MainContent/MainContent.js
import React from 'react';
import { Outlet, useLocation } from "react-router-dom";
import Alert from '../Alert/Alert';
import CountdownTimer from '../Timer/CountdownTimer';

const MainContent = () => {
  const location = useLocation();
  const hotSaleDate = '2024-06-01T00:00:00';

  // Verificar si la ruta actual es login o administración
  const isAdminOrLoginRoute = location.pathname.includes('/dashboard') || location.pathname.includes('/login');

  return (
    <>
      {!isAdminOrLoginRoute && <CountdownTimer targetDate={hotSaleDate} />}
      {!isAdminOrLoginRoute && <Alert />}
      <Outlet />
    </>
  );
};

export default MainContent;
